/* .header {
  font-weight: 500;
  font-size: 20px;
  color: #333;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2f2f2;
}


.css-171rwdv-MuiGrid-root {
  align-items: center !important;
}
.css-1l4fxj8 {
  align-items: center !important;
}

/* Select Time Text in clock at top left */
.css-1hbyad5-MuiTypography-root {
  margin-bottom: 16px !important;
}
/* when hours or minutes is selected */
.css-1nql4ji-MuiTypography-root.Mui-selected {
  background: #edf4fc;
  color: #4a90e2 !important;
}
/* for AM|PM block */
.css-frc9jk-MuiTypography-root {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #DDD;
}
/* AM|PM block selected */
.css-frc9jk-MuiTypography-root.Mui-selected {
  background: #edf4fc;
  color: #4A90E2 !important;
}
/* removed the pencil icon from clock */
.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  /* display: none !important; */
  position: absolute !important;
  bottom: 10px !important;
  color: #777 !important;
}
/* hours|minutes block not selected */
.css-1nql4ji-MuiTypography-root {
  padding: 20px;
  border-radius: 4px;
  background: #ededed;
}

.sliderBranchList {
  position:  absolute !important; 
  top: 30px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  border-radius: 0 0 !important;
  /* overflow: scroll !important; */
  overflow-x: hidden !important;
  box-shadow: 0 0 0 !important;
  max-width: 640px !important;
  margin: auto !important;
}
.selectBranchSliderHeader { 
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 16px 0 !important;
}
.popUpHeaderText {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #1A1A1A !important;
}
.branchListContainer {
  margin: 16px 0 !important;
  height: 100% !important;
}